.button-area {
  display: flex;
  margin: 8px;
}

.button-area > Button {
  margin-left: 8px;
}

#form > * {
  margin-left: 18px;
}

TimePicker {
  width: 120px;
}